export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const UPDATE_FIELD_AUTH = "UPDATE_FIELD_AUTH";
export const LOG_OUT = "LOG_OUT";
export const SET_TOTAL_REVIEWS = "SET_TOTAL_REVIEWS";
export const SET_TOTAL_BUGS_DATA = "SET_TOTAL_BUGS_DATA";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
