import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import * as Yup from "yup";
import { Box, Button, Container, Grid, Link, TextField, Typography, CardMedia, CardHeader, CardContent, Divider, Card } from "@mui/material";
import { LoginRequest } from "../services/index.js";
import { setAuthToken } from "../services/config.js";
import { useEffect } from "react";
import { setOrgDetails } from "../store/actions/index.js";
import { BorderAllRounded } from "@mui/icons-material";

const Website = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (auth.JWT) {
      navigate("dashboard");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      console.log(values.email);
    },
  });

  return (
    <>
      <div style={{ background: "linear-gradient(to bottom right, #2a88aa, white 50%, #F9FAFC, #2a88aa)", minHeight: "100vh" }}>
        <Grid container spacing={2} sx={{ position: "fixed", top: 0, height: 80 }}>
          <Grid item xs={12} md={6} sx={{ alignItems: "center", textAlign: "center", display: "flex", flexGrow: 0, backgroundColor: "#F9FAFC", color: "black", borderColor: "red" }}>
            <Box sx={{ marginLeft: "12%" }}>
              <img src="/static/images/LogoTheme.png" width="220" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ alignItems: "center", alignContent: "center", flexGrow: 0, backgroundColor: "#F9FAFC", color: "black" }}></Grid>
        </Grid>
        <Grid container spacing={2} sx={{ alignItems: "center", textAlign: "center", display: "flex", flexGrow: 1, minHeight: "100%", color: "white", height: 200 }}></Grid>
        <Grid>
          <Grid item xs={12} md={12} sx={{ alignItems: "center", textAlign: "center", minHeight: "100%", color: "white", borderColor: "red" }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h1" sx={{ fontWeight: 'bold', textAlign: 'center', color: '#1D5E77' }}>Empower Your Product</Typography>
                  <Typography variant="h3" sx={{ textAlign: 'center', color: 'black' }}>With AI-Driven Feedback Intelligence</Typography>
                  <Typography variant="h6" sx={{ textAlign: 'center', color: '#333' }}>Harness the power of AI to automatically filter bugs and receive alerts</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={3}>
              <TextField fullWidth id="email" name="email" label="Enter your email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
            </Grid>
            <Grid item xs={12} md={1.4}>
              <Button variant="contained" color="primary" fullWidth type="submit">Request Access</Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="paragraph" color="textSecondary" sx={{ fontWeight: 'bold' }}>Join Feedcapture!</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} sx={{ p: 10 }}>
        <Grid item xs={12} md={6} sx={{}}>
          <img src="/static/images/bugs.png" alt="Big Image" style={{ width: "100%", height: "auto", boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)" }} />
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "center", margin: "auto" }}>
          <Typography variant="h4" gutterBottom>Understanding Your Customers Begins Here</Typography>
          <Typography variant="body1">Leverage our AI-based tool to automatically filter bugs and receive timely alerts.</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ p: 10 }}>
        <Grid item xs={12} md={4} style={{ textAlign: "center", margin: "auto" }}>
          <Typography variant="h4" gutterBottom>A Single Platform for All Your Feedback Data</Typography>
          <Typography variant="body1">Transform qualitative feedback into actionable product improvements. At Feedcapture, we believe every customer review is a valuable asset.</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{}}>
          <img src="/static/images/sources.png" alt="Big Image" style={{ width: "80%", height: "auto", boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)" }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ p: 10 }}>
        <Grid item xs={12} md={6} sx={{}}>
          <img src="/static/images/trend.png" alt="Big Image" style={{ width: "80%", height: "auto", boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)" }} />
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "center", margin: "auto" }}>
          <Typography variant="h4" gutterBottom>Auto-Detect Bugs, Anticipate Future Issues, and Capture Key Insights</Typography>
          <Typography variant="body1">Feedcapture empowers you with speed and agility to detect and address bugs in your product.</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={3}>
          <TextField fullWidth id="email" name="email" label="Enter your email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
        </Grid>
        <Grid item xs={12} md={1.4}>
          <Button variant="contained" color="primary" fullWidth type="submit">Request Access</Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ mb: 10 }}>
        <Typography variant="paragraph" color="textSecondary" sx={{ fontWeight: 'bold' }}>Join Feedcapture!</Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{}}>
        <Typography variant="h3" color="textSecondary" sx={{ fontWeight: 'bold' }}>Feedcapture</Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
        <Typography variant="paragraph" color="textSecondary" sx={{ fontWeight: 'bold' }}>Every Feedback is Valuable</Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
        <Typography variant="h6" color="textSecondary" sx={{ p: 5 }}>Contact us for demo: admin@feedcapture.com</Typography>
        <Typography variant="h6" color="textSecondary" sx={{ p: 5 }}>Phone: +44 7467406061 (Whatsapp)</Typography>
      </Grid>
      <Grid style={{ color: "#fff", textAlign: "center", margin: "auto", backgroundColor: "black" }}>
        <Typography variant="body2">© 2024 Feedcapture. All rights reserved.</Typography>
      </Grid>
    </>
  );
};

export default Website;
